import { createGlobalStyle } from 'styled-components';


import GelionLightFontWoff2 from '../assets/fonts/GelionLight.woff2';
import GelionRegularFontWoff2 from '../assets/fonts/GelionRegular.woff2';
import GelionMediumFontWoff2 from '../assets/fonts/GelionMedium.woff2';
import GelionSemiBoldFontWoff2 from '../assets/fonts/GelionSemiBold.woff2';
import GelionBoldFontWoff2 from '../assets/fonts/GelionBold.woff2';

const GlobalStyle = createGlobalStyle`

@font-face {
	font-family: 'GelionLight';
	src: local('GelionLight'), local('GelionLight'),
	url(${GelionLightFontWoff2}) format('woff2');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'GelionRegular';
	src: local('GelionRegular'), local('GelionRegular'),
	url(${GelionRegularFontWoff2}) format('woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'GelionMedium';
	src: local('GelionMedium'), local('GelionMedium'),
	url(${GelionMediumFontWoff2}) format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'GelionSemiBold';
	src: local('GelionSemiBold'), local('GelionSemiBold'),
	url(${GelionSemiBoldFontWoff2}) format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'GelionBold';
	src: local('GelionBold'), local('GelionBold'),
	url(${GelionBoldFontWoff2}) format('woff2');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
// .container {
// 	content-visibility: auto;
// 	contain-intrinsic-size: 500px;
//   }
`;
export default GlobalStyle;